import React, { useEffect, useState, useRef } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useHistory } from "react-router-dom";
import { Button, Grid, Typography, IconButton } from "@mui/material";

import { useTour } from "../../services/TourContext";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { EntityApi } from "@unity/components";
import config from "../../config";
import { useKeyCheck } from "../../utils/useKeyCheck";

export default function AppTour() {
  let history = useHistory();
  let { tour, setTour, tourStepIndex, setTourStepIndex } = useTour();
  const isLeftPressed = useKeyCheck({ key: 37 });
  const isRightPressed = useKeyCheck({ key: 39 });

  const [tourSteps, setTourSteps] = useState([]);
  const [tourRun, setTourRun] = useState(false);

  const tourStepsRef = useRef();
  const tourStepIndexRef = useRef(0);
  const tourStepForwards = useRef(true);

  const incTourStepIndex = (inc) => {
    setTourStepIndex((prevIndex) => {
      const index = prevIndex + inc;
      tourStepIndexRef.current = index;
      tourStepForwards.current = true;
      return index;
    });
  };
  const decTourStepIndex = (dec) => {
    setTourStepIndex((prevIndex) => {
      const index = prevIndex - dec;
      tourStepIndexRef.current = index;
      tourStepForwards.current = false;
      return index;
    });
  };

  const fetchTourData = async () => {
    const localTourData = await EntityApi.getEntityByType(
      config.tourStepsEntityTypeId,
      tour.module
    );
    return localTourData;
  };

  useEffect(() => {
    /*
To advance the tour from somewhere else in the app, dispatch an event. See below.
NOTE: use a negative number for step index to go back in the tour.
window.dispatchEvent(new CustomEvent("tourStepIndex",{detail:{stepIndex:<number of steps to advance the tour>}}))
    */
    window.addEventListener("tourStepIndex", function (e) {
      if (e.detail.stepIndex) {
        setTimeout(
          () => setTourStepIndex((prev) => prev + e.detail.stepIndex),
          1000
        );
      }
    });
  }, []);

  useEffect(() => {
    //to open a tour from anywhere, dispatch an event. See below.
    /*
    window.dispatchEvent(new CustomEvent("tourOpen",{detail:{module:"<module_alias>""}}))
    */
    window.addEventListener("tourOpen", function (e) {
      if (e.detail.module) {
        setTour({ open: true, module: e.detail.module });
      }
    });
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (tour?.open && tour?.module) {
        let stepsData = await fetchTourData();
        if (stepsData && stepsData.success) {
          if (stepsData.data.data.length > 0) {
            const steps = buildSteps(stepsData.data.data[0]);
            setTourStepIndex(0);
            setTourSteps(steps);
            tourStepIndexRef.current = 0;
            tourStepsRef.current = steps;
            setTourRun(true);
          } else {
            alert("No tour is available for the selected module.");
          }
        }
      }
    };
    fetch();
  }, [tour]);

  useEffect(() => {
    if (document.querySelector('[leftkey=""]') && isLeftPressed) {
      doStepBackward();
    }
  }, [isLeftPressed]);

  useEffect(() => {
    if (document.querySelector('[rightkey=""]') && isRightPressed) {
      doStepForward();
    }
  }, [isRightPressed]);

  const handleClose = () => {
    setTour({ open: false, module: null });
    setTourRun(false);
    window.dispatchEvent(new CustomEvent("tour", { detail: { open: false } }));
  };

  const doStepForward = () => {
    if (tourStepIndexRef.current + 1 < tourStepsRef.current.length) {
      const step = tourStepsRef.current[tourStepIndexRef.current];
      let inc = 1;
      if (step && step.forwardUrl && step.forwardUrl.length > 0) {
        history.push(`/${tour.module}${step.forwardUrl}`);
        setTimeout(() => incTourStepIndex(inc), 1000);
      } else {
        incTourStepIndex(inc);
      }
    }
  };

  const doStepBackward = () => {
    const step = tourStepsRef.current[tourStepIndexRef.current];
    let dec = 1;
    if (step && step.backwardUrl && step.backwardUrl.length > 0) {
      history.push(`/${tour.module}${step.backwardUrl}`);
      setTimeout(() => decTourStepIndex(dec), 1000);
    } else {
      decTourStepIndex(dec);
    }
  };

  const toTitleCase = (txt) =>
    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();

  const generalStep = (content) => (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography align="left">{content}</Typography>
      </Grid>
      <Grid item>
        <IconButton
          leftkey=""
          variant="contained"
          onClick={() => doStepBackward()}
        >
          <ArrowBack />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          rightkey=""
          variant="contained"
          onClick={() => doStepForward()}
        >
          <ArrowForward />
        </IconButton>
      </Grid>
    </Grid>
  );

  const sectionStartStep = (content) => (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography align="left">{content}</Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={() => setTourRun(false)}>
          cancel
        </Button>
      </Grid>
      <Grid item>
        <Button rightkey="" variant="contained" onClick={() => doStepForward()}>
          begin
        </Button>
      </Grid>
    </Grid>
  );

  const finishStep = (content) => (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography align="left">{content}</Typography>
      </Grid>
      <Grid item>
        <IconButton
          leftkey=""
          variant="contained"
          onClick={() => doStepBackward()}
        >
          <ArrowBack />
        </IconButton>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={() => setTourRun(false)}>
          finish
        </Button>
      </Grid>
    </Grid>
  );

  const nextStep = (content) => (
    <Grid container spacing={3} justifyContent="flex-end" alignItems="center">
      <Grid item xs={12}>
        <Typography align="left">{content}</Typography>
      </Grid>
      <Grid item>
        <IconButton
          rightkey=""
          variant="contained"
          onClick={() => doStepForward()}
        >
          <ArrowForward />
        </IconButton>
      </Grid>
    </Grid>
  );

  const previousStep = (content) => (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <Typography align="left">{content}</Typography>
      </Grid>
      <Grid item>
        <IconButton
          leftkey=""
          variant="contained"
          onClick={() => doStepBackward()}
        >
          <ArrowBack />
        </IconButton>
      </Grid>
    </Grid>
  );

  const buildSteps = (stepsData) => {
    const steps = [];
    let itemIncludedCount = stepsData.steps.length;
    let itemIncludedIndex = 0;
    for (let item of stepsData.steps) {
      if (itemIncludedIndex === 0) {
        item.content = sectionStartStep(item.content);
      } else if (itemIncludedIndex === itemIncludedCount - 1) {
        item.content = finishStep(item.content);
      } else {
        if (item.disableStepBack) {
          item.content = nextStep(item.content);
        } else if (item.disableStepNext) {
          item.content = previousStep(item.content);
        } else {
          item.content = generalStep(item.content);
        }
      }
      itemIncludedIndex++;
      item.title = `${toTitleCase(
        tour.module
      )} Tour (${itemIncludedIndex} of ${itemIncludedCount})`;
      item.disableBeacon = true;
      steps.push(item);
    }
    return steps;
  };

  const tourCallback = (data) => {
    const { action, type, status } = data;
    if (type === EVENTS.TARGET_NOT_FOUND && action === ACTIONS.NEXT) {
      doStepForward();
    }

    if (type === EVENTS.TARGET_NOT_FOUND && action === ACTIONS.PREV) {
      doStepBackward();
    }

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === ACTIONS.CLOSE ||
      action === ACTIONS.STOP
    ) {
      handleClose();
    }
  };

  //Known issue: https://github.com/gilbarbara/react-joyride/issues/1004
  //causesoverlay to be missing from dom on reopen of tour
  //fix will be pulled into main, so will test after then
  return (
    <Joyride
      continuous={true}
      run={tourRun}
      stepIndex={tourStepIndex}
      steps={tourSteps}
      showProgress={false}
      callback={tourCallback}
      disableOverlay={false} // Handy for debugging
      disableOverlayClose={true}
      scrollToFirstStep={true}
      spotlightPadding={6}
      scrollOffset={100}
      styles={{
        tooltipContainer: {
          whiteSpace: "pre-wrap",
        },
        buttonNext: {
          display: "none",
        },
        buttonBack: {
          display: "none",
        },
        buttonSkip: {
          display: "none",
        },
        tooltipFooter: {
          display: "none",
        },
        options: {
          zIndex: 5000,
        },
      }}
    />
  );
}
